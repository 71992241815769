<template>
  <gw-dialog v-model="dialog" hide-actions>
    <template #activator="{ on }">
      <v-btn color="primary" icon text v-on="on">
        <icon-edit />
      </v-btn>
    </template>

    <gw-form-observer
      :loading="loading"
      cancel-label="close"
      hide-top-actions
      no-nest
      :reset.sync="resetForm"
      @cancel="onClose"
      @save="onSave"
    >
      <v-row>
        <v-col cols="12" md="12">
          <product-select
            :value="item.product_id"
            single-line
            without-generated
            sell
            @change="onSelectProduct"
          />
        </v-col>

        <v-col cols="12" md="4">
          <currency-select
            v-model="item.currency_id"
            :disabled="!obCustomer || useDefaultSettings"
            :ids="arCustomerCurrencyIdList"
            required
          />
        </v-col>

        <v-col cols="12" md="4">
          <form-field-text
            v-model="item.quantity"
            input-type="number"
            label="quantity"
            required
          />
        </v-col>

        <v-col cols="12" md="4">
          <unit-price-field
            :currency-id="item.currency_id"
            :disabled="!item.currency_id || !obProduct"
            :offer="offer"
            :price-with-tax="priceWithTax"
            :single-line="false"
            :tax-type="obTaxType"
            :value="item.price"
            @price:update="onSetPrice"
          />
        </v-col>

        <v-col cols="12" md="3">
          <form-field-date-picker
            v-model="item.start_at"
            :disabled="useDefaultSettings"
            label="date.from"
          />
        </v-col>

        <v-col cols="12" md="3">
          <form-field-date-picker
            v-model="item.end_at"
            :disabled="useDefaultSettings"
            label="date.to"
          />
        </v-col>

        <v-col cols="12" md="3">
          <accounting-movement-type-select
            v-model="item.accounting_movement_type_id"
            :disabled="useDefaultSettings"
            market-type="buy"
            omit-market-type
            required
            @change="onSelectMovementType"
          />
        </v-col>

        <v-col cols="12" md="3">
          <payment-terms-select
            v-model="item.payment_term_id"
            :disabled="!isCredit || useDefaultSettings"
            :payment-terms="arCustomerPaymentTermList"
            @change="onSelectPaymentTerm"
          />
        </v-col>

        <v-col cols="12" md="12">
          <contract-description
            :description="item.name"
            @change="onDecriptionChange"
          />
        </v-col>

        <v-col cols="12" md="12" v-if="false">
          <invoice-labels-dialog
            v-model="dialogLabels"
            :position="item"
            :product="product"
            field="DscItem"
          />
        </v-col>
      </v-row>
    </gw-form-observer>
  </gw-dialog>
</template>

<script lang="ts">
import {
  Component,
  Inject,
  Prop,
  VModel,
  Vue,
  Watch,
} from "vue-property-decorator";
import GwDialog from "@/components/common/GwDialog.vue";
import IconEdit from "@/components/icons/IconEdit.vue";
import GwFormObserver from "@/components/form/GwFormObserver.vue";
import { Ref } from "@/types/utils";
import {
  AccountingMovementType,
  Contract,
  ContractPosition,
  ContractPositionData,
  Customer,
  OfferData,
  ProductGwData,
  TaxType,
  TimePeriod,
  TimePeriodData,
} from "@planetadeleste/vue-mc-gw";
import { Product } from "@planetadeleste/vue-mc-shopaholic";
import useTimePeriod, {
  TimeFrequencyCalc,
  TimePeriodFunction,
} from "@/composables/timePeriod";
import { get, isEmpty, isString, map } from "lodash";
import { PaymentTermData } from "@planetadeleste/vue-mc-gw/src/types";
import AccountingMovementTypeSelect from "@/modules/accountingentries/components/AccountingMovementTypeSelect.vue";
import ProductPreview from "@/modules/products/components/ProductPreview.vue";
import PaymentTermsSelect from "@/modules/paymentterms/components/PaymentTermsSelect.vue";
import CurrencySelect from "@/modules/currencies/components/CurrencySelect.vue";
import ProductSelect from "@/modules/products/components/ProductSelect.vue";
import TaxTypePreview from "@/modules/taxtypes/components/TaxTypePreview.vue";
import UnitPriceField from "@/modules/invoices/components/UnitPriceField.vue";
import FormFieldDatePicker from "@/components/form/fields/DatePicker.vue";
import ContractDescription from "../ContractDescription.vue";
import InvoiceLabelsDialog from "@/modules/invoices/components/InvoiceLabelsDialog.vue";
import { array, object, string, number, ValidationError } from "yup";

@Component({
  components: {
    FormFieldDatePicker,
    UnitPriceField,
    TaxTypePreview,
    ProductSelect,
    CurrencySelect,
    PaymentTermsSelect,
    ProductPreview,
    AccountingMovementTypeSelect,
    GwFormObserver,
    IconEdit,
    GwDialog,
    ContractDescription,
    InvoiceLabelsDialog,
  },
})
export default class PositionRowForm extends Vue {
  @VModel({ type: Boolean, default: () => false }) dialog!: boolean;

  @Inject() readonly customer!: Ref<Customer>;
  @Inject() readonly contract!: Ref<Contract>;
  @Inject() readonly defaultSettings!: Ref<Partial<ContractPositionData>>;

  @Prop(Object) readonly item!: ContractPosition; // TODO agregar cammpo description a node_modules/@planetadeleste/vue-mc-gw/src/types/ContractPosition.d.ts
  @Prop(Boolean) readonly priceWithTax!: boolean;

  resetForm = false;
  loading = false;
  obProduct: Product | null = null;
  obTaxType: TaxType | null = null;
  obMovementType: AccountingMovementType | null = null;
  obPeriodHelper: TimePeriodFunction | null = null;
  dialogLabels = false;

  obSchema = object({
    contractDescription: string().required("La Descripcion es obligatoria"),
    accountingMovement: string().required(
      "El Tipo de comprobante es obligatorio"
    ),
    //endAt: string().required("La Fecha hasta es obligatoria"),
    startAt: string().required("La Fecha desde es obligatoria"),
    price: number()
      .moreThan(-1, "El Precio unitario es obligatorio")
      .nullable(),
    quantity: string().required("La Cantidad es obligatoria"),
    currencySelect: string().required("La Moneda es obligatoria"),
    productSelect: string().required("El Articulo es obligatorio"),
  });

  get obContract(): Contract | undefined {
    return this.contract?.value;
  }

  get obCustomer(): Customer | undefined {
    return this.customer?.value;
  }

  get useDefaultSettings(): boolean {
    return !isEmpty(this.defaultSettings.value);
  }

  get arCustomerCurrencyIdList(): number[] {
    return this.obCustomer ? map(this.obCustomer.currencies, "id") : [];
  }

  get arCustomerPaymentTermList(): PaymentTermData[] {
    return this.obCustomer ? this.obCustomer.paymentterms : [];
  }

  get offer(): OfferData | undefined {
    return this.obProduct?.get("offer");
  }

  get obProductGW(): ProductGwData | undefined {
    return this.obProduct?.get("product_gw");
  }

  get isCredit(): boolean {
    return (
      this.useCashCredit &&
      !!this.obMovementType &&
      !this.obMovementType.is_cash
    );
  }

  get useCashCredit(): boolean {
    return (
      !!this.obMovementType && this.obMovementType.get("use_cash_credit", false)
    );
  }

  get obPeriod(): Partial<TimePeriodData> {
    const obModel = this.obContract ? this.obContract.get("period", {}) : {};

    return obModel instanceof TimePeriod ? obModel.attributes : obModel;
  }

  get sStartAt() {
    return this.item.start_at;
  }

  get getProductSelect() {
    return this.item.product_id;
  }

  get getCurrencySelect() {
    return this.item.currency_id;
  }

  get getQuantity() {
    return this.item.quantity;
  }

  get getPrice() {
    return this.item.price;
  }

  get getStartAt() {
    return this.item.start_at;
  }

  get getEndAt() {
    return this.item.end_at;
  }

  get getAccountingMovement() {
    return this.item.accounting_movement_type_id;
  }

  get getContractDescription() {
    return this.item.name;
  }

  get product(): number {
    return this.obProduct?.get("id");
  }

  @Watch("obPeriod", { deep: true })
  onChangePeriod(obData: TimeFrequencyCalc, obOldData?: TimeFrequencyCalc) {
    if (
      this.sStartAt &&
      obData.frequency &&
      obData.frequency_interval &&
      (!obOldData ||
        obOldData.frequency !== obData.frequency ||
        obOldData.frequency_interval !== obData.frequency_interval)
    ) {
      this.obPeriodHelper = useTimePeriod(this.sStartAt, obData);
    }
  }

  @Watch("sStartAt")
  onChangeStartAt() {
    this.onChangePeriod(this.obPeriod as TimeFrequencyCalc);
  }

  async onSelectProduct(obItem: Product) {
    if (this.item.product_id == obItem.id) return;
    this.item.product_id = obItem.id;
    this.obProduct = obItem;
    this.$emit("change:product", obItem);
    this.item.set("product", obItem);
    this.item.name = obItem.name;

    if (this.obProductGW?.taxtype_id) {
      this.obTaxType = new TaxType({ id: this.obProductGW.taxtype_id });
      await this.obTaxType.fetch();
    }
  }

  onSelectMovementType(obItem: AccountingMovementType) {
    this.obMovementType = obItem;
    this.item.set("movement_type", obItem);
    this.$emit("change:movement-type", obItem);
    this.$emit("change:credit", this.isCredit);
  }

  onSelectPaymentTerm(obItem: PaymentTermData) {
    this.item.set("payment_term", obItem);
    this.$emit("change:payment-term", obItem);
  }

  onSetPrice(fValue: number) {
    this.item.set("price", fValue);
  }

  onDecriptionChange(fDescription: string) {
    //this.item.set("name", fDescription);
    this.item.name = fDescription;
  }

  async validate() {
    try {
      await this.obSchema.validate(
        {
          productSelect: this.getProductSelect,
          currencySelect: this.getCurrencySelect,
          quantity: this.getQuantity,
          price: this.getPrice,
          startAt: this.getStartAt,
          endAt: this.getEndAt,
          accountingMovement: this.getAccountingMovement,
          contractDescription: this.getContractDescription,
        },
        { abortEarly: false }
      );
      if (!this.item.get("price")) {
        const bRes = await this.$confirm(
          "Importes en cero, se guarda el contrato sin valor",
          { color: "warning" }
        );
        return bRes;
      }
      return true;
      // @ts-ignore
    } catch (err: ValidationError) {
      return err.errors.reduce(
        (error: string, finalError: string) => `${finalError}\n${error}`,
        ""
      );
    }
  }

  async onClose() {
    this.$emit("close");
    this.resetForm = true;
  }

  async onSave() {
    const sResponse = await this.validate();

    if (isString(sResponse)) {
      this.$toast.error(sResponse);
      return;
    } else if (sResponse) {
      this.$emit("save", this.item);
      this.resetForm = true;
    }
  }

  mounted() {
    this.onChangePeriod(this.obPeriod as TimeFrequencyCalc);
  }
}
</script>
